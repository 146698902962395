import { graphql } from 'gatsby'
import PageAddBusiness from '../components/PageAddBusiness'

export const query = graphql`
  query PageAddBusiness {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default PageAddBusiness
